<template>
  <div
    :class="[
      'organism map-arbitrary',
      organism.contentTheme,
      organism.mapArbitraryLayout,
      'container-' + organism.containerWidth,
    ]"
    :style="'background-color:' + organism.backgroundColor"
  >
    <div
      v-if="
        organism.mapArbitraryLayout === 'layout-2-col-map-content' ||
        organism.mapArbitraryLayout === 'layout-2-col-content-map'
      "
      class="mx-auto grid grid-cols-1 md:grid-cols-2 min-h-[400px]"
    >
      <div id="map-wrap" class="w-full h-[500px]">
        <div id="map" class="w-full h-full"></div>
      </div>
      <div
        :class="[
          organism.mapArbitraryLayout === 'layout-2-col-content-map'
            ? 'md:-order-1'
            : '',
          'content w-full max-w-full px-12 py-10 lg:py-20 lg:max-w-2xl',
        ]"
      >
        <h3 class="mb-3 theme-text-eyebrow">{{ organism.title2 }}</h3>
        <h2 class="mb-5 text-2xl md:text-3xl lg:text-5xl theme-text-2xl">
          {{ organism.title1 }}
        </h2>
        <div
          class="mx-auto text-lg text-gray-500 description mb-9 max-w-screen-md font-figtree leading-6"
          v-html="organism.description"
        ></div>
        <MoleculesButtons :buttons="organism" :button-colors="buttonColors" />
      </div>
    </div>
    <div
      v-else
      class="py-16 mx-auto text-center max-w-screen-lg layout-default"
    >
      <h3 class="px-8 mx-auto mb-8 lg:px-0 theme-text-eyebrow">
        {{ organism.title2 }}
      </h3>
      <h2
        class="px-8 mx-auto mb-8 text-2xl lg:px-0 md:text-3xl lg:text-5xl theme-text-2xl"
      >
        {{ organism.title1 }}
      </h2>
      <div id="map-wrap" class="w-full h-[500px] md:h-[700px]">
        <div id="map" class="w-full h-full"></div>
      </div>
      <div
        class="px-8 mx-auto text-lg text-gray-500 lg:px-0 description mb-9 max-w-screen-md font-figtree leading-6"
        v-html="organism.description"
      ></div>
      <div class="px-8 lg:px-0">
        <MoleculesButtons
          class="!justify-center"
          :buttons="organism"
          :button-colors="buttonColors"
        />
      </div>
    </div>
  </div>
</template>

<style>
.map-arbitrary {
  .layout-default #map-wrap {
    @apply w-full aspect-[3/2] mb-12;
  }

  &.layout-2-col-map-content,
  &.layout-2-col-content-map {
    #map-wrap {
      @apply w-full h-full;
    }
    .buttons a {
      @apply leading-none py-3 text-[15px];
    }
  }

  &.light-text {
    @apply text-white;

    .description {
      @apply text-white;
    }
  }

  .gm-style-iw {
    @apply !max-h-fit;
    .gm-style-iw-d {
      @apply !max-h-fit;
    }
  }

  .gm-style .gm-style-iw[role="dialog"] > button::before {
    @apply content-[''] bg-[url('/img/cancel-circle.svg')] bg-no-repeat bg-center bg-[length:24px_24px] w-full h-full block;
  }
  .infowindow-content {
    .title {
      @apply font-figtree text-2xl text-black font-bold text-center mb-3 px-4 mt-10;
    }

    .address {
      @apply font-figtree text-sm font-medium text-bc-wc-green text-center uppercase px-4 mb-3;
      .address-1 {
        @apply block;
      }
      .address-2 {
        @apply block;
      }
      .city {
        &::after {
          content: ", ";
        }
      }
      .state {
      }
      .zip {
      }
    }

    .phone-link {
      @apply block text-bc-dark-blue font-figtree text-base underline text-center flex justify-center gap-1 items-center font-medium mb-4 px-1;
    }
    .phone-link::before {
      @apply content-[''] bg-bc-dark-blue bg-[url('/img/phone-icon--solid-white.svg')] bg-no-repeat bg-center bg-[length:8px_8px] w-4 h-4 block rounded-full;
    }
  }
}
</style>

<script setup>
const props = defineProps({
  organism: Object,
});

const buttonColors = computed(() => {
  const buttonColorsArray = [];
  if (props.organism.button1Color) {
    buttonColorsArray.push(props.organism.button1Color);
  }
  if (props.organism.button2Color) {
    buttonColorsArray.push(props.organism.button2Color);
  }
  if (props.organism.button3Color) {
    buttonColorsArray.push(props.organism.button3Color);
  }
  return buttonColorsArray;
});

const infoWindowContentString = computed(() => {
  return `
      <div class="infowindow-content infowindow-map-arbitrary">
        <h2 class="title">${props.organism.markerTitle ?? ""}</h2>
        <h3 class="address">
          <span class="address-1">${props.organism.address1 ?? ""}</span>
          <span class="address-2">${props.organism.address2 ?? ""}</span>
          <span class="city">${props.organism.city ?? ""}</span>
          <span class="state">${props.organism.state ?? ""}</span>
          <span class="zip">${props.organism.zip ?? ""}</span>
        </h3>
        <a class="phone-link" href="tel:${
          props.organism.phone ?? ""
        }" target="_blank">${props.organism.phone ? "Call Us" : ""}</a>
        <a class="explore-button" target="${
          props.organism.button1Target ?? ""
        }" href="${props.organism.link1 ?? ""}">
          ${props.organism.button1Text ?? ""}
        </a>
      </div>
    `;
});

const mapInfo = computed(() => {
  if (!props.organism.latitude || !props.organism.longitude) {
    return false;
  }
  return {
    mapView: "map-arbitrary",
    latitude: props.organism.latitude,
    longitude: props.organism.longitude,
    title: props.organism.markerTitle,
    infoWindowContent: infoWindowContentString.value,
  };
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }

  loadMapArbitraryMap();
  updateMapArbitraryMap(mapInfo.value);
});

watch(mapInfo, () => {
  updateMapArbitraryMap(mapInfo.value);
});
</script>
