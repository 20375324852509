<template>
  <div
    :class="[
      'organism tabbed-content-generic',
      'container-' + organism.containerWidth,
    ]"
  >
    <OrganismsTabbedContent :organism="tabbedContent">
      <template v-for="item in tabbedContent.item" #[item.id]>
        <component
          v-for="(organism, index) in item?.item?.item"
          :id="
            organism.id
              ? organism.id
              : item.id + '-' + organism.type + '-' + (index + 1)
          "
          :class="[
            organism.cssClasses,
            organism.type,
            'tab-item-' + (index + 1),
            item.id + '-' + organism.type + '-' + (index + 1),
          ]"
          :is="
            organism.type === 'accordion'
              ? OrganismsAccordion
              : organism.type === 'arbitrary-home-grid'
              ? OrganismsArbitraryHomeGrid
              : organism.type === 'arbitrary-plan-grid'
              ? OrganismsArbitraryPlanGrid
              : organism.type === 'tabbed-videos'
              ? OrganismsTabbedVideos
              : organism.type === 'wysiwyg-block'
              ? OrganismsWysiwygBlock
              : 'div'
          "
          :organism="organism"
        />
      </template>
    </OrganismsTabbedContent>
  </div>
</template>

<style>
.tabbed-content-generic {
  .container-full-width {
    @apply !max-w-none translate-x-0;
  }

  & > header {
    background-image: linear-gradient(101deg, #1b3f68 0%, #265a94 97%);
  }

  .tabbed-content {
    & > header {
      background-image: linear-gradient(101deg, #1b3f68 0%, #265a94 97%);
    }

    .tabs-component {
      .tabs-component-tabs {
        @apply max-w-7xl mx-auto justify-center;

        .tabs-component-tab {
          @apply md:max-w-[276px];

          a[role="tab"] > span {
            @apply leading-none;
          }

          .tab-subtitle {
            @apply text-sm font-inter;
          }
        }
      }

      .tabs-component-panels {
        @apply px-0;
      }
    }
  }
}
</style>

<script setup>
const props = defineProps({
  organism: Object,
});

const OrganismsAccordion = resolveComponent("OrganismsAccordion");
const OrganismsArbitraryHomeGrid = resolveComponent(
  "OrganismsArbitraryHomeGrid"
);
const OrganismsArbitraryPlanGrid = resolveComponent(
  "OrganismsArbitraryPlanGrid"
);
const OrganismsTabbedVideos = resolveComponent("OrganismsTabbedVideos");
const OrganismsWysiwygBlock = resolveComponent("OrganismsWysiwygBlock");

const tabbedContent = computed(() => {
  const tabs = props?.organism?.item.map((item) => {
    const name = item.title2
      ? '<span class="tab-title">' +
        item.title1 +
        "</span><br>" +
        '<span class="tab-subtitle">' +
        item.title2 +
        "</span>"
      : '<span class="tab-title">' + item.title1 + "</span>";
    return {
      name: name,
      id: "tab-" + kebabCase(item.title1).replace(/-{2,}/g, "-"),
      item: item,
    };
  });

  // Don't load Tabbed Content organism until we have tab data
  if (tabs.length === 0) {
    return {
      containerWidth: "default",
      collectionIcons: "",
      title1: props?.organism?.title1,
      title2: props?.organism?.title2,
      description: props?.organism?.description,
    };
  } else {
    return {
      containerWidth: "default",
      collectionIcons: "",
      title1: props?.organism?.title1,
      title2: props?.organism?.title2,
      description: props?.organism?.description,
      item: tabs,
      defaultTabHash: tabs.length ? tabs?.[0]?.id : "",
    };
  }
});

onMounted(() => {
  if (props?.organism?.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
