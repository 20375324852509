<template>
  <div class="organism my-favorites-grid">
    <OrganismsHeadingTransition
      :organism="{
        title1: 'Your Favorite Floorplans',
        arrow: true,
      }"
    />
    <OrganismsProductGrid
      v-if="!loading && plans.length"
      class="px-8 pb-20 pt-11"
      :organism="plans"
      type="floorplans"
    />
    <OrganismsModalLogin />
  </div>
</template>

<style>
.my-favorites-grid {
}
</style>

<script setup>
import { useQuery } from "@vue/apollo-composable";
import { GET_NEW_HOME_SEARCH } from "~/graphql/GetNewHomeSearch";
const { result, loading } = useQuery(GET_NEW_HOME_SEARCH);

const props = defineProps({
  organism: Object,
});

const plans = computed(() => {
  return result?.value?.plans?.nodes ?? [];
});
</script>
