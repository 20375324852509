<template>
  <ul class="overview-item-group mb-11" v-if="hasVisibleItems">
    <li v-if="group.areaTitle">
      <AtomsOverviewItemGroupHeading>
        {{ group.areaTitle }}
      </AtomsOverviewItemGroupHeading>
    </li>
    <template v-for="(item, index) in filteredItems" :key="item.id">
      <li v-if="shouldDisplayItem(item)" :class="{'border-b-2 border-gray-400/50': index !== filteredItems.length - 1}">
        <MoleculesOverviewItem :item-raw-data="item" :tab-id="tabId" :plans="group.plans" />
      </li>
    </template>
  </ul>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  group: Object,
  tabId: String,
});

const shouldDisplayItem = (item) => {
  return item && (
    (item.__typename === 'Neighborhood' && item.neighborhood && item.neighborhood.hideCommunity !== true) ||
    (item.__typename === 'Model' && item.modelDetails?.neighborhood?.neighborhood && item.modelDetails.neighborhood.neighborhood.hideCommunity !== true) ||
    (item.__typename === 'Home' && item.qmiHomeDetails?.neighborhood?.neighborhood && item.qmiHomeDetails.neighborhood.neighborhood.hideCommunity !== true && item.qmiHomeDetails.hideOnMap !== true)
  );
};

const filteredItems = computed(() => {
  return props.group.items.filter(item => shouldDisplayItem(item));
});

const hasVisibleItems = computed(() => {
  return filteredItems.value.length > 0;
});
</script>