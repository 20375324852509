<template>
  <div
    :class="[
      'bg-gray-200 organism banner-bar',
      'container-' + organism.containerWidth,
    ]"
  >
    <div
      class="flex flex-wrap items-center justify-center px-8 py-4 mx-auto text-center md:justify-start md:text-left max-w-7xl gap-4"
    >
      <StarIcon class="w-5 h-5 text-gray-900 stroke-white" aria-hidden="true" />
      <h2 class="font-figtree font-semibold text-[25px] text-white leading-none">
        {{ organism.title1 }}
      </h2>
      <NuxtLink
        :to="organism.link1"
        :title="organism.button1Text"
        class="rounded shadow bg-amber-400 font-figtree font-medium text-[13px] px-4 py-1"
        >{{ organism.button1Text }}</NuxtLink
      >
    </div>
  </div>
</template>

<style>
.organism.banner-bar {
  background-image: linear-gradient(101deg, #1b3f68 0%, #265a94 97%);
}
</style>

<script setup>
import { StarIcon } from "@heroicons/vue/outline/index.js";
const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
