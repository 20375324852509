<template>
  <div
    :class="['organism wysiwyg-block', 'container-' + organism.containerWidth]"
    :style="'background-color:' + organism.backgroundColor"
  >
    <div class="wrapper">
      <div class="text-content" v-html="organism.description"></div>
      <MoleculesButtons
        v-if="organism.button1"
        :buttons="organism"
        :button-colors="[
          organism.button1Color ?? 'button-blue',
          organism.button2Color ?? 'button-white',
          organism.button3Color ?? 'button-white',
        ]"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
