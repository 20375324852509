<template>
  <div
    :class="['organism locations-list', 'container-' + organism.containerWidth]"
  >
    <div
      v-for="location in locations"
      class="px-8 py-12 location even:bg-fr-beige"
    >
      <div class="mx-auto max-w-screen-xl grid lg:grid-cols-2 gap-x-12">
        <div>
          <div
            v-for="collection in collectionsByLocation.find(
              (item) => item.databaseId === location.databaseId,
            ).collections"
            class="mt-4 lg:mt-0"
          >
            <AtomsCollectionButton :collection="collection" />
          </div>
          <h2 class="text-3xl font-semibold lg:text-5xl title-1 font-figtree">
            {{ location.title }}
          </h2>
          <h3 class="text-base font-semibold text-gray-500 uppercase">
            {{
              getNeighborhoodNavSubtitle(
                location?.locationDetails?.neighborhood[0]?.types,
                getRoundedPrice(
                  location?.locationDetails?.neighborhood[0]?.basePrice,
                ),
                location?.locationDetails?.neighborhood[0]?.neighborhood
                  ?.status,
              )
            }}
          </h3>
          <div class="py-12 grid grid-cols-1 sm:grid-cols-3 gap-5">
            <ul>
              <li
                class="relative mb-5 phone ml-9"
                v-if="location.locationDetails.phone"
              >
                <a
                  :href="'tel:' + location.locationDetails.phone"
                  class="underline text-fr-secondary-dark"
                >
                  {{ location.locationDetails.phone }}
                </a>
              </li>
              <li class="relative mb-5 address ml-9">
                <a
                  :href="
                    'https://www.google.com/maps/place/' +
                    location.locationDetails.latitude +
                    ',' +
                    location.locationDetails.longitude
                  "
                  target="_blank"
                  class="underline text-fr-secondary-dark"
                >
                  {{ location.locationDetails.address1 }},
                  {{ location.locationDetails.address2 }}<br />{{
                    location.locationDetails.city
                  }}, {{ location.locationDetails.state }}
                  {{ location.locationDetails.zip }}
                </a>
              </li>
              <li class="relative mb-5 info ml-9">
                <button
                  @click="modalForm.openModal($event)"
                  class="underline text-fr-secondary-dark"
                >
                  Request info
                </button>
              </li>
            </ul>
            <div class="hours" v-if="location.locationDetails.hours">
              <h4 class="text-lg font-semibold text-bc-dark-blue">Hours:</h4>
              <div
                class="text-sm text-gray-500"
                v-html="location.locationDetails.hours"
              ></div>
            </div>
            <div
              v-if="
                modelsByLocation.find(
                  (item) => item.databaseId === location.databaseId,
                ).models?.length
              "
            >
              <h4 class="text-lg font-semibold text-bc-dark-blue">Models:</h4>
              <ul class="list-disc marker:text-gray-500">
                <li
                  v-for="model in modelsByLocation.find(
                    (item) => item.databaseId === location.databaseId,
                  ).models"
                  class="ml-5"
                >
                  <a :href="model.uri" class="text-fr-secondary-dark">{{
                    model.title
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
          <MoleculesButtons
            :buttons="{
              button1: true,
              link1: location?.locationDetails?.neighborhood?.[0]?.uri,
              button1Text: 'Explore this neighborhood',
            }"
          />
        </div>
        <img
          :src="getImageSrc(location.locationDetails.image, 'medium_large')"
          alt=""
          class="object-cover w-full h-full aspect-[2/1] lg:aspect-auto"
        />
      </div>
    </div>
  </div>
</template>

<style>
.locations-list {
  .location:nth-child(odd) > div > img {
    @apply order-first lg:order-none;
  }
  .location:nth-child(even) > div > img {
    @apply order-first;
  }

  li.phone {
    &::before {
      @apply absolute w-6 h-6 -left-8;
      content: url("/img/heroicon-phone.svg");
    }
  }
  li.address {
    &::before {
      @apply absolute w-6 h-6 -left-8;
      content: url("/img/icon-map-pin-basic.svg");
    }
  }
  li.info {
    &::before {
      @apply absolute w-6 h-6 -left-8;
      content: url("/img/user-chat-bubble.svg");
    }
  }
}
</style>
<script setup>
import { useQuery } from "@vue/apollo-composable";
import { GET_LOCATIONS } from "~/graphql/GetLocations";
import { storeToRefs } from "pinia";
const overview = useOverview();
const { plans, models } = storeToRefs(overview);
const modalForm = useModalForm();

const { result } = useQuery(GET_LOCATIONS);
const locations = computed(() => {
  return result?.value?.locations?.nodes ?? [];
});

const collectionsByLocation = computed(() => {
  return locations.value.map((location) => {
    return {
      databaseId: location?.databaseId,
      collections: getCollectionsByNeighborhood(
        location?.locationDetails?.neighborhood[0]?.slug,
        plans.value.nodes,
        null,
        null,
        [location?.locationDetails?.neighborhood[0]?.collection?.collection],
      ),
    };
  });
});

const modelsByLocation = computed(() => {
  return locations.value.map((location) => {
    return {
      databaseId: location?.databaseId,
      models: models?.value?.nodes?.filter(
        (item) =>
          item?.modelDetails?.neighborhood?.databaseId ===
          location?.locationDetails?.neighborhood[0]?.databaseId,
      ),
    };
  });
});

const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
