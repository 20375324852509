<template>
  <div
    :class="[
      'organism tabbed-products',
      'container-' + organism.containerWidth,
    ]"
  >
    <OrganismsTabbedContent :organism="tabbedContent">
      <template v-for="item in tabbedContent.item" #[item.id]>
        <div
          v-if="item.neighborhood.neighborhood.description"
          class="description max-w-[805px] mx-auto text-gray-500 text-lg [&>p]:leading-7 text-justify mb-12"
          v-html="item.neighborhood.neighborhood.description"
        ></div>
        <div
          v-if="plansByNeighborhoodSlug[item.id].length"
          class="pt-12 bg-fr-beige mb-11 container-full-width"
        >
          <h3
            class="text-2xl font-bold leading-none text-center text-gray-800 font-figtree sm:text-[32px]"
          >
            Floor Plans
          </h3>
          <OrganismsProductGrid
            v-if="plansByNeighborhoodSlug[item.id].length"
            class="px-8 pb-20 mx-auto pt-11 max-w-7xl"
            :organism="plansByNeighborhoodSlug[item.id]"
            type="floorplans"
          />
        </div>
        <div
          v-if="homesByNeighborhoodSlug[item.id].length"
          class="pt-12 bg-fr-beige mb-11 container-full-width"
        >
          <h3
            class="text-2xl font-bold leading-none text-center text-gray-800 font-figtree sm:text-[32px]"
          >
            Quick Move-Ins
          </h3>
          <OrganismsProductGrid
            v-if="homesByNeighborhoodSlug[item.id].length"
            class="px-8 pb-20 mx-auto pt-11 max-w-7xl"
            :organism="homesByNeighborhoodSlug[item.id]"
            type="quick-move-in"
          />
        </div>
        <div
          v-if="modelsByNeighborhoodSlug[item.id].length"
          class="mb-11 pt-11 bg-fr-beige container-full-width"
        >
          <h3
            class="text-2xl font-bold leading-none text-center text-gray-800 font-figtree sm:text-[32px]"
          >
            Model Homes
          </h3>
          <OrganismsProductGrid
            v-if="modelsByNeighborhoodSlug[item.id].length"
            class="px-8 pb-20 mx-auto pt-11 max-w-7xl"
            :organism="modelsByNeighborhoodSlug[item.id]"
            type="model-homes"
          />
        </div>
      </template>
    </OrganismsTabbedContent>
  </div>
</template>

<style>
.tabbed-products {
  & > header {
    background-image: linear-gradient(101deg, #1b3f68 0%, #265a94 97%);
  }

  .tabbed-content {
    & > header {
      background-image: linear-gradient(101deg, #1b3f68 0%, #265a94 97%);
    }

    .tabs-component {
      .tabs-component-tabs {
        @apply max-w-7xl mx-auto justify-center;

        .tabs-component-tab {
          @apply md:max-w-[276px];

          a[role="tab"] > span {
            @apply leading-none;
          }

          &.is-disabled {
            @apply bg-gray-400;

            a {
              @apply cursor-not-allowed;
            }
          }

          .tab-subtitle {
            @apply text-sm font-inter;
          }
        }
      }

      .tabs-component-panels {
      }
    }
  }
}
</style>

<script setup>
import { useQuery } from "@vue/apollo-composable";
import { GET_PRODUCTS_OVERVIEW } from "~/graphql/GetProductsOverview";
const { result } = useQuery(GET_PRODUCTS_OVERVIEW);

const neighborhoods = computed(() => result?.value?.neighborhoods?.nodes ?? []);
const models = computed(() => result?.value?.models?.nodes ?? []);
const homes = computed(() => result?.value?.homes?.nodes ?? []);
const plans = computed(() => result?.value?.plans?.nodes ?? []);

const props = defineProps({
  organism: Object,
});

const neighborhoodSlugsByCollection = computed(() => {
  const neighborhoodSlugs = [];

  if (Array.isArray(plans.value)) {
    plans.value.forEach((plan) => {
      let neighborhood = plan.floorplanDetails.neighborhood.slug;

      if (
        plan.floorplanDetails.collection === props.organism.collection &&
        neighborhoodSlugs.indexOf(neighborhood) === -1
      ) {
        neighborhoodSlugs.push(plan.floorplanDetails.neighborhood.slug);
      }
    });
  }

  if (Array.isArray(neighborhoods.value)) {
    neighborhoods.value.forEach((neighborhood) => {
      if (
        neighborhood.collection.collection === props.organism.collection &&
        neighborhoodSlugs.indexOf(neighborhood.slug) === -1
      ) {
        neighborhoodSlugs.push(neighborhood.slug);
      }
    });
  }

  return neighborhoodSlugs;
});

const plansByNeighborhoodSlug = computed(() => {
  const plansByNeighborhood = {};

  neighborhoodSlugsByCollection.value.forEach((slug) => {
    plansByNeighborhood[slug] = plans?.value?.filter((plan) => {
      return plan.floorplanDetails.neighborhood.slug === slug;
    });
  });

  return plansByNeighborhood;
});

const homesByNeighborhoodSlug = computed(() => {
  const homesByNeighborhood = {};

  neighborhoodSlugsByCollection.value.forEach((slug) => {
    homesByNeighborhood[slug] = homes?.value?.filter((home) => {
      return home.qmiHomeDetails.neighborhood.slug === slug;
    });
  });

  return homesByNeighborhood;
});

const modelsByNeighborhoodSlug = computed(() => {
  const modelsByNeighborhood = {};

  neighborhoodSlugsByCollection.value.forEach((slug) => {
    modelsByNeighborhood[slug] = models?.value?.filter((model) => {
      return model?.modelDetails?.neighborhood?.slug === slug;
    });
  });

  return modelsByNeighborhood;
});

const tabbedContent = computed(() => {
  const neighborhoodsFiltered = neighborhoods?.value.filter((neighborhood) => {
    return (
      neighborhoodSlugsByCollection.value.indexOf(neighborhood.slug) !== -1
    );
  });

  const tabs = neighborhoodsFiltered.map((neighborhood) => {
    const productCount =
      plansByNeighborhoodSlug.value[neighborhood.slug].length +
      homesByNeighborhoodSlug.value[neighborhood.slug].length +
      modelsByNeighborhoodSlug.value[neighborhood.slug].length;

    const priceString = neighborhood.basePrice
      ? '<span class="tab-subtitle">From $' +
        getRoundedPrice(neighborhood.basePrice) +
        "</span>"
      : '<span class="tab-subtitle pricing-coming-soon">Pricing Coming Soon</span>';
    return {
      name:
        '<span class="tab-title">' +
        neighborhood.title +
        "</span><br>" +
        priceString,
      id: neighborhood.slug,
      neighborhood: neighborhood,
      isDisabled: !productCount ? true : false,
    };
  });

  // Don't load Tabbed Content organism until we have tab data
  if (tabs.length === 0) {
    return {
      containerWidth: "default",
      collectionIcons: "",
      title1: props?.organism?.title1,
      title2: props?.organism?.title2,
    };
  } else {
    return {
      containerWidth: "default",
      collectionIcons: "",
      title1: props?.organism?.title1,
      title2: props?.organism?.title2,
      item: tabs,
      defaultTabHash: tabs.length ? tabs?.[1]?.id : "",
    };
  }
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
