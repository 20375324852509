<template>
  <div class="organism arbitrary-plan-grid">
    <OrganismsProductGrid
      class="px-8 pb-20 pt-11"
      :organism="selectedPlans"
      type="floorplans"
    />
  </div>
</template>

<script setup>
import { useQuery } from "@vue/apollo-composable";
import { GET_NEW_HOME_SEARCH } from "~/graphql/GetNewHomeSearch";
const { result } = useQuery(GET_NEW_HOME_SEARCH);

const props = defineProps({
  organism: Object,
});

const plans = computed(() => {
  return result?.value?.plans?.nodes ?? [];
});

const selectedPlans = computed(() => {
  const selectedIds = props?.organism?.selectPlans?.map(
    (plan) => plan.databaseId
  );
  return plans?.value?.filter((plan) => selectedIds.includes(plan.databaseId));
});
</script>
