<template>
  <div class="max-w-5xl mx-auto organism hubspot-form">
    <header
      v-if="organism.formHeader"
      v-html="organism.formHeader"
      class="mb-4"
    ></header>
    <FormsHubSpotForm :form="form" />
  </div>
</template>

<script setup>
const props = defineProps({
  organism: Object,
});

const form = computed(() => {
  return {
    id: props.organism?.hubspotFormId,
    hiddenFields: props.organism?.hiddenFormFields ?? [],
  };
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
