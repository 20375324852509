<template>
  <div
    :class="[
      'organism single-product-grid',
      'container-' + organism.containerWidth,
    ]"
  >
    <div
      v-if="organism.productType === 'quick-move-in'"
      class="pt-12 bg-fr-beige mb-11 container-full-width"
    >
      <h2
        class="text-2xl font-bold leading-none text-center text-gray-800 font-figtree sm:text-[32px]"
      >
        {{ organism.title1 }}
      </h2>
      <OrganismsProductGrid
        v-if="homes?.length"
        class="px-8 pb-16 mx-auto pt-11 max-w-7xl"
        :organism="homes"
        type="quick-move-in"
      />
      <MoleculesButtons class="!justify-center pb-24" :buttons="organism" />
    </div>
  </div>
</template>

<style>
.single-product-grid {
}
</style>

<script setup>
import { useQuery } from "@vue/apollo-composable";
import { GET_PRODUCTS_OVERVIEW } from "~/graphql/GetProductsOverview";
const { result } = useQuery(GET_PRODUCTS_OVERVIEW);

const homes = computed(() => result?.value?.homes?.nodes ?? []);

const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
