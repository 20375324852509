import gql from "graphql-tag";
import { FRAGMENT_MEDIA_ITEM } from "./fragments.js";

export const GET_VIDEOS = gql`
  ${FRAGMENT_MEDIA_ITEM}
  query GetVideos {
    videoCategories {
      nodes {
        name
        slug
        contentNodes {
          nodes {
            ... on Video {
              slug
              title
              uri
              featuredImage {
                node {
                ...MediaItemFragment
                }
              }
              videoOptions {
                description
                fieldGroupName
                subtitle
                videoUrl
              }
            }
          }
        }
      }
    }
  }
`;



