<template>
  <Swiper
    :class="[
      'organism organism-slider',
      'container-' + organism.containerWidth,
      isSlideHidden ? 'hide-duplicate-slide' : '',
    ]"
    :navigation="true"
    :loop="true"
    :modules="modules"
    :pagination="true"
    :autoplay="{delay: 5000}"
    @init="onSwiper"
  >
    <SwiperSlide
      v-for="(organism, index) in organism.item"
      :key="'slider-organism' + index"
      class="select-none"
    >
      <component
        :id="organism.id ? organism.id : 'organism-' + (index + 1)"
        :class="[organism.cssClasses, organism.type, 'organism-' + (index + 1)]"
        :is="
          organism.type === 'brand-window' &&
          organism.brandWindowLayout === 'box-overlay'
            ? OrganismsBWBoxOverlay
            : organism.type === 'brand-window' &&
              organism.brandWindowLayout === 'split-slant'
            ? OrganismsBWSlant
            : organism.type === 'brand-window' &&
              (organism.brandWindowLayout === 'text-overlay-left' ||
                organism.brandWindowLayout === 'text-overlay-center')
            ? OrganismsBWTextOverlay
            : organism.type === 'image'
            ? OrganismsImage
            : organism.type === 'simple-centered'
            ? OrganismsSimpleCentered
            : organism.type === 'split-content'
            ? OrganismsSplitContent
            : organism.type === 'testimonial-cta'
            ? OrganismsTestimonialCTA
            : organism.type === 'virtual-tour'
            ? OrganismsVirtualTour
            : organism.type === 'wysiwyg-block'
            ? OrganismsWysiwygBlock
            : 'div'
        "
        :organism="organism"
      />
    </SwiperSlide>
  </Swiper>
</template>

<style>
.organism-slider {
  &.hide-duplicate-slide {
    .swiper-slide-duplicate {
      @apply first:hidden;
    }
  }

  &.swiper {
    --swiper-theme-color: #559cbe;
    --swiper-navigation-size: 1.5rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    @apply bg-white w-10 h-10 rounded-full shadow-lg;
  }

  .swiper-button-prev {
    @apply left-1 md:left-[10px];
  }

  .swiper-button-next {
    @apply right-1 md:right-[10px];
  }

  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    @apply bg-gray-200;
  }
}
</style>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const modules = [Navigation, Pagination, Autoplay];

const OrganismsBWBoxOverlay = resolveComponent("OrganismsBWBoxOverlay");
const OrganismsBWSlant = resolveComponent("OrganismsBWSlant");
const OrganismsBWTextOverlay = resolveComponent("OrganismsBWTextOverlay");
const OrganismsImage = resolveComponent("OrganismsImage");
const OrganismsSimpleCentered = resolveComponent("OrganismsSimpleCentered");
const OrganismsSplitContent = resolveComponent("OrganismsSplitContent");
const OrganismsTestimonialCTA = resolveComponent("OrganismsTestimonialCTA");
const OrganismsVirtualTour = resolveComponent("OrganismsVirtualTour");
const OrganismsWysiwygBlock = resolveComponent("OrganismsWysiwygBlock");

const props = defineProps({
  organism: Object,
});

const isSlideHidden = ref(true);

const onSwiper = (swiper) => {
  isSlideHidden.value = false;
};

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});

const autoplaySlider = () => {
  if (props.organism.autoplaySlider && props.organism.autoplayDelay) {
    return { delay: props.organism.autoplayDelay };
  }

  return false;
};
</script>
