<template>
  <div
    :class="[
      'organism rounded-image-bio px-5',
      'container-' + organism.containerWidth,
    ]"
    :style="'background-color:' + organism.backgroundColor"
  >
    <div
      class="grid grid-cols-1 grid-rows-[119px_1fr] md:grid-cols-[174px_1fr] md:grid-rows-[72px_1fr_72px] max-w-5xl mx-auto"
    >
      <div
        class="rounded-[30px] rounded-background row-start-2 row-span-1 col-start-1 col-span-1 md:col-start-2 md:row-start-1 md:col-span-1 md:row-span-3"
      ></div>
      <div
        class="items-center content row-start-1 row-span-2 col-start-1 col-span-1 md:col-span-2 md:col-start-1 md:row-start-2 md:row-span-1 md:grid md:grid-cols-[348px_1fr]"
      >
        <img
          :src="getImageSrc(organism.image, 'medium_large')"
          class="rounded-[30px] w-[min(238px,_80%)] mx-auto md:w-[348px] aspect-square object-cover object-top"
          alt=""
          loading="lazy"
        />
        <div
          class="text pt-5 pb-8 px-6 lg:px-[72px] flex flex-col justify-center"
        >
          <h2
            class="text-3xl sm:text-[44px] font-figtree text-white font-bold m-0 leading-none mb-4"
          >
            <span
              class="title-1 mr-[0.5ch] md:block md:float-left md:clear-both"
              >{{ organism.title1 }}</span
            >
            <span
              class="title-2 text-bc-bright-blue md:block md:float-left md:clear-both"
              >{{ organism.title2 }}</span
            >
          </h2>
          <div class="description" v-html="organism.description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.rounded-image-bio {
  .rounded-background {
    background-image: linear-gradient(101deg, #1b3f68 0%, #265a94 97%);
  }

  .description {
    p {
      @apply text-white;
    }
  }
}
</style>

<script setup>
const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
