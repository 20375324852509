import gql from "graphql-tag";
import { FRAGMENT_MEDIA_ITEM } from "./fragments.js";

export const GET_PRODUCTS_OVERVIEW = gql`
  ${FRAGMENT_MEDIA_ITEM}
  query GetProductsOverview {
    neighborhoods(last: 500) {
      nodes {
        basePrice
        databaseId
        id
        slug
        status
        title
        types
        uri
        collection {
          collection
        }
        neighborhood {
          address1
          address2
          city
          description
          latitude
          longitude
          neighborhoodArea
          state
          status
          thumbnail {
            altText
            sourceUrl
            mediaDetails {
              sizes {
                file
                fileSize
                height
                mimeType
                name
                sourceUrl
                width
              }
            }
          }
        }
      }
    }
    plans(last: 500) {
      nodes {
        databaseId
        id
        slug
        status
        title
        uri
        floorplanDetails {
          bathroomsMax
          bathroomsMin
          bedroomsMax
          bedroomsMin
          collection
          displayTitle
          finishedSqrFt
          mainSqrFt
          elevations {
            ...MediaItemFragment
          }
          features {
            feature
          }
          neighborhood {
            ... on Neighborhood {
              databaseId
              id
              slug
              title
            }
          }
        }
      }
    }
    models(last: 500) {
      nodes {
        databaseId
        id
        slug
        status
        title
        uri
        featuredImage {
          node {
            altText
            slug
            sourceUrl
            title
            uri
            mediaDetails {
              sizes {
                file
                fileSize
                height
                mimeType
                name
                sourceUrl
                width
              }
            }
          }
        }
        modelDetails {
          latitude
          longitude
          elevations {
            ...MediaItemFragment
          }
          floorplan {
            ... on Plan {
              floorplanDetails {
                bathroomsMax
                bathroomsMin
                bedroomsMax
                bedroomsMin
                collection
                finishedSqrFt
                mainSqrFt
                features {
                  feature
                }
              }
            }
          }
          neighborhood {
            ... on Neighborhood {
              basePrice
              databaseId
              id
              slug
              title
              types
              neighborhood {
                neighborhoodArea
                status
                thumbnail {
                  altText
                  sourceUrl
                  mediaDetails {
                    sizes {
                      file
                      fileSize
                      height
                      mimeType
                      name
                      sourceUrl
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    homes(last: 500) {
      nodes {
        databaseId
        id
        slug
        title
        uri
        featuredImage {
          node {
            altText
            slug
            sourceUrl
            title
            uri
            mediaDetails {
              sizes {
                file
                fileSize
                height
                mimeType
                name
                sourceUrl
                width
              }
            }
          }
        }
        qmiHomeDetails {
          bathrooms
          bedrooms
          finishedSqrFt
          latitude
          longitude
          price
          type
          elevations {
            ...MediaItemFragment
          }
          floorplan {
            ... on Plan {
              title
              floorplanDetails {
                collection
                features {
                  feature
                }
              }
            }
          }
          neighborhood {
            ... on Neighborhood {
              slug
              neighborhood {
                neighborhoodArea
                thumbnail {
                  altText
                  sourceUrl
                  mediaDetails {
                    sizes {
                      file
                      fileSize
                      height
                      mimeType
                      name
                      sourceUrl
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
