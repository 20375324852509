<template>
  <div
    :class="['organism timeline py-24', 'container-' + organism.containerWidth]"
    :style="'background-color:' + organism.backgroundColor"
  >
    <div class="px-8 mx-auto max-w-7xl">
      <h2 class="pb-5 text-2xl font-bold text-center uppercase font-opensans">
        {{ organism.title1 }}
      </h2>
      <div
        v-if="organism.description"
        class="pb-5 description md:pb-0"
        v-html="organism.description"
      ></div>
      <ul
        class="md:mt-10 grid gap-8"
        :style="
          isDesktop
            ? 'grid-template-columns: repeat(' +
              organism?.item?.length +
              ', minmax(0, 1fr));'
            : 'grid-template-columns: repeat(1, minmax(0, 1fr));'
        "
      >
        <li
          v-for="(item, index) in organism.item"
          class="flex flex-row items-center text-left md:text-center group md:flex-col"
        >
          <div
            class="dashed-line w-0 md:w-[calc(100%_+_32px)] h-[calc(100%_+_32px_+_20px)] md:h-0 border-l-[3px] md:border-l-0 md:border-t-[3px] border-gray-400 border-dashed md:-mb-5 relative -z-10 top-[calc(50%_+_20px)] md:top-0 left-[22px] md:left-1/2 group-last-of-type:h-0 md:group-last-of-type:w-0"
          ></div>
          <div
            class="flex items-center justify-center w-10 h-10 mr-4 text-xl text-white rounded-full md:mb-4 md:mx-auto bg-bc-purple aspect-square"
          >
            {{ index + 1 }}
          </div>
          <p class="text-sm font-bold">{{ item.title1 }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<style>
.timeline {
  ul {
    li {
      div {
      }
    }
  }
}
</style>

<script setup>
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
const breakpoints = useBreakpoints(breakpointsTailwind);
const isDesktop = breakpoints.greater("md");

const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
