<template>
  <div class="organism arbitrary-home-grid">
    <OrganismsProductGrid
      class="px-8 pb-20 pt-11"
      :organism="selectedHomes"
      type="quick-move-in"
    />
  </div>
</template>

<script setup>
import { useQuery } from "@vue/apollo-composable";
import { GET_HOMES_OVERVIEW } from "~/graphql/GetQMIs";
const { result } = useQuery(GET_HOMES_OVERVIEW);

const props = defineProps({
  organism: Object,
});

const homes = computed(() => result?.value?.homes?.nodes ?? []);

const selectedHomes = computed(() => {
  const selectedIds = props?.organism?.selectHomes?.map(
    (home) => home.databaseId
  );
  const test = homes?.value?.filter((home) =>
    selectedIds?.includes(home.databaseId)
  );
  return test;
});
</script>
