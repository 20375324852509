import gql from "graphql-tag";
import { FRAGMENT_MEDIA_ITEM } from "./fragments.js";

export const GET_TEAM_DETAILS = gql`
  ${FRAGMENT_MEDIA_ITEM}
  query GetTeamDetails($uri: ID = "/about-us/our-team/") {
    page(id: $uri, idType: URI) {
      teamDetails {
        departments {
          department
          teamMembers {
            name
            position
            description
            photo {
            ...MediaItemFragment
            }
          }
        }
      }
    }
  }
`;
