import gql from "graphql-tag";
import { FRAGMENT_MEDIA_ITEM } from "./fragments.js";
import { FRAGMENT_NEIGHBORHOOD_DETAILS } from "./fragments.js";

export const GET_LOCATIONS = gql`
  ${FRAGMENT_MEDIA_ITEM}
  ${FRAGMENT_NEIGHBORHOOD_DETAILS}
  query GetLocations {
    locations(last: 500) {
      nodes {
        databaseId
        id
        title
        uri
        locationDetails {
          phone
          latitude
          longitude
          state
          zip
          city
          address2
          address1
          hours
          image {
            ...MediaItemFragment
          }
          neighborhood {
            ...NeighborhoodDetailsFragment
          }
        }
      }
    }
  }
`;
