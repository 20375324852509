<template>
  <div class="organism past-events">
    <OrganismsHeadingTransition
      :organism="{ title1: 'Past Events', arrow: true }"
      class="mb-8"
    />
    <div
      class="xl:mx-auto mx-8 max-w-7xl grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 md:gap-x-8 gap-y-12"
    >
      <MoleculesEventCard v-for="event in pastEvents" :molecule="event" />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
const events = useEvents();
const { eventsList } = storeToRefs(events);
import { format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

const pastEvents = computed(() => {
  return eventsList?.value
    .filter((event) => {
      const eventDate = zonedTimeToUtc(
        event.eventDetails.startDateTime,
        "America/Denver"
      );
      return new Date() > new Date(eventDate);
    })
    .map((event) => {
      const eventDate = zonedTimeToUtc(
        event.eventDetails.startDateTime,
        "America/Denver"
      );
      const eventDateFormatted = format(new Date(eventDate), "MMM d, yyyy");
      return {
        collection: event.eventDetails.collection,
        description: event.content,
        id: "past-event-" + event.slug,
        image: event.eventDetails.image,
        title: event.title,
        eventDate: eventDateFormatted,
        button1: true,
        button1Text: "Get Details",
        link1: event.uri,
        unixTimestamp: Date.parse(eventDate),
      };
    })
    .sort((a, b) => b.unixTimestamp - a.unixTimestamp);
});
</script>
