<template>
  <div
    :class="[
      'organism tabbed-videos pt-12 bg-fr-beige',
      'container-' + organism?.containerWidth,
    ]"
  >
    <h2
      class="px-8 mb-3 leading-none font-figtree text-3xl sm:text-[44px] font-semibold text-gray-900 max-w-7xl mx-auto"
    >
      {{ organism.title1 }}
    </h2>
    <OrganismsTabbedContent :organism="tabbedContent">
      <template v-for="item in tabbedContent.item" #[item.id]>
        <div v-for="category in videoCategories" class="mx-auto max-w-7xl">
          <ul
            v-if="category.slug === item.id"
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
          >
            <li v-for="video in category.contentNodes.nodes">
              <button
                @click="modalVideo.openModal(video.videoOptions.videoUrl)"
                class="hover:opacity-80"
              >
                <img
                  :src="getImageSrc(video.featuredImage.node, 'large')"
                  :alt="video.title"
                  loading="lazy"
                />
                <div>
                  {{ video.title }}
                </div>
              </button>
            </li>
          </ul>
        </div>
      </template>
    </OrganismsTabbedContent>
    <OrganismsModalVideo />
  </div>
</template>

<style>
.tabbed-videos {
  & > header {
    @apply !bg-none;
  }

  .tabbed-content {
    & > header {
      @apply !bg-none !pt-4;
    }

    .tabs-component {
      .tabs-component-tabs {
        @apply max-w-7xl mx-auto flex flex-col md:flex-row !justify-start md:gap-x-8 lg:gap-x-28 xl:gap-x-32 rounded-none px-8;

        .tabs-component-tab {
          @apply rounded-none bg-fr-beige drop-shadow-none !max-w-min block;

          &.is-active {
            @apply !bg-fr-beige border-b-4 border-b-fr-brown-med;

            a[role="tab"] > span {
              @apply text-fr-secondary-dark;
            }
          }

          a[role="tab"] {
            @apply w-fit block;
            span {
              @apply leading-none text-left font-normal font-figtree text-gray-500 px-0 w-fit block whitespace-nowrap;
            }
          }

          .tab-subtitle {
            @apply text-sm font-inter;
          }
        }
      }

      .tabs-component-panels {
        @apply bg-white py-16 !px-8;
        box-shadow: inset 0 5px 5px -5px rgba(51, 52, 51, 0.5);
      }
    }
  }
}
</style>

<script setup>
const modalVideo = useModalVideo();
import { useQuery } from "@vue/apollo-composable";
import { GET_VIDEOS } from "~/graphql/GetVideos";
const { result } = useQuery(GET_VIDEOS);

const videoCategories = computed(
  () => result?.value?.videoCategories?.nodes ?? []
);

const props = defineProps({
  organism: Object,
});

const tabbedContent = computed(() => {
  const tabs = videoCategories.value.map((category) => {
    return {
      name: category.name,
      id: category.slug,
    };
  });

  // Don't load Tabbed Content organism until we have tab data
  if (tabs.length === 0) {
    return {
      hideOrganism: true,
    };
  } else {
    return {
      containerWidth: "default",
      collectionIcons: "",
      item: tabs,
      defaultTabHash: tabs.length ? tabs?.[1]?.id : "",
    };
  }
});

onMounted(() => {
  if (props?.organism?.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
